<template>
  <div>
    <div class="nav">
      <div class="nav-list">
        <div class="nav-item">Copyright © 许继绿能有限公司</div>
        <a class="nav-item" v-for="item in link" :key="item.id">{{ item.title }}</a>
      </div>
      <div class="share-list">
        <div class="share-item">
          <img
            src="https://www.nio.cn/themes/nioweb/images/icon-wechat.svg"
            alt=""
          />
        </div>
        <div class="share-item">
          <img
            src="https://www.nio.cn/themes/nioweb/images/icon-weibo.svg"
            alt=""
          />
        </div>
        <div class="share-item">
          <img
            src="https://www.nio.cn/themes/nioweb/images/icon-phone.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="copyright">
      &nbsp;&nbsp;备案号
      <a href="http://www.beian.miit.gov.cn">{{ website.icp }}</a>
      <span>
        &nbsp;&nbsp; 技术支持：<a href="https://www.pigwan.net">Pigwan</a>
      </span>
    </div>
  </div>
</template>

<script>
import state from "../store/state";
export default {
  name: "Footer",
  data() {
    return {
      link: [],
      website:{}
    };
  },
  mounted() {
    this.link = state.websiteSet.link;
    this.website = state.websiteSet.website
  },
};
</script>

<style scoped>
.nav {
  border-bottom: 1px dashed #bcbcbc;
  position: relative;
}
.nav-list {
  font-weight: 500;
  position: relative;
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
.nav-item {
  display: inline-block;
  margin: 0 20px;
  color: #000000;
}
.share-list {
  display: inline-block;
  float: right;
  margin-right: 20px;
}
.share-item {
  display: inline-block;
  margin: 0 10px;
}
.copyright {
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  color: #000000;
  font-weight: 300;
}
.copyright a:link {
  color: #000000;
}
</style>
