<template>
  <div
    style="
      width: 100%;
      background-color: #ffffff;
      position: fixed;
      z-index: 100;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    "
  >
    <Menu></Menu>
  </div>
  <router-view></router-view>
  <div v-if="this.$route.path !== '/home'" style="width: 100%;margin-top: 80px">
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from "./Menu";
import Footer from "./Footer";

export default {
  name: "BaseLayout",
  components: {
    Menu,
    Footer,
  },
  mounted() {

  }
};
</script>

<style scoped></style>
