<template>
  <div class="p-menu">
    <div class="p-logo">
      <img
        src="https://oss.pigwan.net/wapi/upload/images/2021/12/199e9e2fd39cb1ba73a5282644e296445c.png"
        alt=""
      />
    </div>
    <div class="p-nav">
      <a-menu
        mode="horizontal"
        :selectedKeys="selectedKeys"
        style="
          font-size: 12px;
          border-bottom: none;
          line-height: 50px;
          background: none;
        "
      >
        <!--        循环导航-->
        <template v-for="item in navData" :key="item.nav_key">
          <!--          有子分类-->
          <template
            v-if="
              item.children && item.children.length > 0 && item.status === 1
            "
          >
            <a-sub-menu :key="item.id">
              <template #title>
                <router-link :to="item.link">
                  {{ item.title }}
                </router-link>
              </template>
<!--              <template v-for="item2 in item.children" :key="item2.nav_key">-->
<!--                <a-menu-item>-->
<!--                  <router-link :to="{path:item.link,query: {cate_id: item2.id}}">-->
<!--                    {{ item2.title }}-->
<!--                  </router-link>-->
<!--                </a-menu-item>-->
<!--              </template>-->
            </a-sub-menu>
          </template>
          <!--          无子分类-->
          <template v-else-if="item.status === 1">
            <a-menu-item :key="item.id">
              <router-link :to="item.link">
                {{ item.title }}
              </router-link>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </div>
    <div
      class="p-contact"
      :style="contactHoverActive"
      @mouseenter="contactEnter()"
      @mouseleave="contactLeave()"
      @click="routerContact()"
    >
      联系我们
    </div>
  </div>
</template>

<script>
import { Menu, MenuItem, SubMenu } from "ant-design-vue/es";
import state from "../store/state";
import router from "../router";

export default {
  name: "Menu",
  data() {
    return {
      selectedKeys: [],
      navData: [],
      contactHoverActive: ""
    };
  },
  components: {
    AMenu: Menu,
    AMenuItem: MenuItem,
    ASubMenu: SubMenu
  },
  mounted() {
    this.navData = state.websiteSet.nav;
    console.log(this.navData);
  },
  methods: {
    contactEnter() {
      this.contactHoverActive = "background-color: #000000;color:#FFFFFF";
    },
    contactLeave() {
      this.contactHoverActive = "background-color: #FFFFFF;color:#000000";
    },
    routerContact() {
      router.push({
        path: "/about"
      });
    }
  }
};
</script>

<style scoped>
a {
  color: #000000;
}

.p-menu {
  max-width: 1200px;
  height: 50px;
  display: flex;
  position: relative;
  margin: auto;
  font-size: 12px;
}

.p-logo {
  height: 24px;
  line-height: 50px;
  margin-left: 30px;
}

.p-logo img {
  height: 100%;
  object-fit: contain;
}

.p-logo,
.p-nav,
.p-contact {
  display: inline-block;
  vertical-align: middle;
}

.p-contact {
  width: 120px;
  border: 1px solid #000000;
  margin-top: 12px;
  right: 25px;
  height: 26px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  cursor: pointer;
}
</style>
